import {ApiUrls} from "../enums/ApiUrls";
import {AxiosResponse} from "axios";
import {getHttpClient} from "./API";

export interface VehicleConfiguration {
  vehicleLine: string;
  configurations: [
    {
      body: string;
      code: string;
    }
  ];
}

export function getCurrentOfferingConfigurations(): Promise<
  AxiosResponse<VehicleConfiguration[]>
> {
  const httpClient = getHttpClient();
  return httpClient.get<VehicleConfiguration[]>(
    ApiUrls.getVehicleLineConfigurations
  );
}

export function getOrdersFor(userSalesCodes: string | null) {
  let requestURL: string = ApiUrls.orders;
  if (userSalesCodes != null) {
    requestURL = requestURL + userSalesCodes;
  }
  return getHttpClient().get(requestURL);
}

export async function getAllRegions() {
  let response = await getHttpClient().get(ApiUrls.regions);
  return response.data;
}

export async function getAllVehicleLineDescriptions(
  vehicleLine: string,
  country: string
) {
  let requestURL =
    ApiUrls.getAllVehicleLineDescriptions + vehicleLine + "/" + country;
  return await getHttpClient().get(requestURL);
}
