import React, {useEffect, useState} from "react";
import styles from "../../styles/AdminPage.module.scss";
import {
  getCompletedOfferingsForAllCountries,
  Offering,
} from "../../service/OfferingService";
import {RebillingElement} from "./RebillingElement";

export function RebillOfferingAdminTool() {
  const [offerings, setOfferings] = useState<Offering[]>([]);

  useEffect(() => {
    getCompletedOfferingsForAllCountries().then((response) => {
      setOfferings(response);
    });
  }, []);

  return (
    <>
      <h2 className={styles.rebillingHeader}>Latest Completed Offerings</h2>
      <div className={styles.rebillingWrapper}>
        {offerings.map((offering, index) => (
          <RebillingElement offering={offering} key={index} />
        ))}
      </div>
    </>
  );
}
