export enum Country {
  US = "US",
  CA = "CA",
  MX = "MX",
}

export namespace Country {
  export function toDisplayString(country: Country): String {
    switch (country) {
      case Country.CA:
        return "Canada";
      case Country.US:
        return "USA";
      case Country.MX:
        return "Mexico";
      default:
        return "Unknown";
    }
  }
}
