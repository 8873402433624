import React, {useState} from "react";
import {Offering, postOfferingToRebilling} from "../../service/OfferingService";

import styles from "../../styles/AdminPage.module.scss";

function ConfirmRebilling(props: {
  setModalVisibilityFalse: () => void;
  confirmSubmission: () => void;
}) {
  return (
    <div className="fmc-dialog__content">
      <div className={styles["rebillingModal"]}>
        <h2>Rebill Offering?</h2>
        <strong>Are you sure you wish to rebill the current offering?</strong>
        <p>This action cannot be undone.</p>

        <div className={styles["admin-tool-rebill-button"]}>
          <button
            className={"fmc-button fmc-button--outlined"}
            onClick={() => props.setModalVisibilityFalse()}>
            Go Back
          </button>
          <button className={"fmc-button"} onClick={props.confirmSubmission}>
            Yes, Rebill Now
          </button>
        </div>
      </div>

      <button
        className={"fmc-dialog__close"}
        onClick={() => props.setModalVisibilityFalse()}>
        X
      </button>
    </div>
  );
}

function SuccessfulRebilling(props: {setModalVisibilityFalse: () => void}) {
  return (
    <div className="fmc-dialog__content">
      <div className={styles["rebillingModal"]}>
        <h2>Success</h2>
        <strong>
          The offering has been submitted for rebilling successfully
        </strong>
        <p>You may now close this window.</p>
        <div className={styles["admin-tool-rebill-button"]}>
          <button
            className={"fmc-button fmc-button--outlined"}
            onClick={() => props.setModalVisibilityFalse()}>
            Close
          </button>
        </div>
      </div>
      <button
        className={"fmc-dialog__close"}
        onClick={() => props.setModalVisibilityFalse()}>
        X
      </button>
    </div>
  );
}

function ErrorRebilling(props: {setModalVisibilityFalse: () => void}) {
  return (
    <div className="fmc-dialog__content">
      <div className={styles["rebillingModal"]}>
        <h2>Error</h2>
        <strong>
          An error has occurred when trying to submit the offering for rebilling
        </strong>
        <p>Please close this message and try again later.</p>
        <div className={styles["admin-tool-rebill-button"]}>
          <button
            className={"fmc-button fmc-button--outlined"}
            onClick={() => props.setModalVisibilityFalse()}>
            Close
          </button>
        </div>
      </div>
      <button
        className={"fmc-dialog__close"}
        onClick={() => props.setModalVisibilityFalse()}>
        X
      </button>
    </div>
  );
}

export function RebillingModal(props: {
  setModalVisibilityFalse: () => void;
  offering: Offering;
}) {
  const [modalState, setModalState] = useState<Boolean | null>(null);

  function confirmSubmission() {
    postOfferingToRebilling(props.offering._id).then((status) => {
      if (status === 200) {
        setModalState(true);
      } else {
        setModalState(false);
      }
    });
  }

  return (
    <div className="confirmation-modal fmc-dialog fds-align--center fmc-dialog--opened">
      {modalState === null && (
        <ConfirmRebilling
          setModalVisibilityFalse={props.setModalVisibilityFalse}
          confirmSubmission={confirmSubmission}
        />
      )}
      {modalState === true && (
        <SuccessfulRebilling
          setModalVisibilityFalse={props.setModalVisibilityFalse}
        />
      )}
      {modalState === false && (
        <ErrorRebilling
          setModalVisibilityFalse={props.setModalVisibilityFalse}
        />
      )}
    </div>
  );
}
