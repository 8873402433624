import React, {LegacyRef, useEffect, useRef, useState} from "react";
import {getCurrentOfferingConfigurations} from "../../service/OrderService";
import {TableColumn} from "../../models/TableColumn";
import "../../styles/SupplementalVehicleRequests.scss";
import {
  createSupplementalVehicle,
  getSupplementalVehiclesCSV,
  OfferedVehicle,
} from "../../service/SupplementalVehicleService";
import {Table} from "antd";
import Modal from "react-modal";
import {useGlobalState} from "../../state/GlobalState";
import {SalesCodeSelector} from "../../components/orderGridFilters/SalesCodeSelector";
import {SVRDownloadButton} from "../../components/supplementalVehicleRequests/SVRDownloadButton";
import {getSkin, Skin} from "../../service/SkinService";
import {TextTranslation} from "../../utils/Translations";

export function SupplementalVehicleRequests() {
  const [salesCode, setSalesCode] = useGlobalState("salesCode");
  const [hasAdminPermissions] = useGlobalState("hasAdminPermissions");
  const [hasReadAllPermissions] = useGlobalState("hasReadAllPermissions");
  const [offeredVehicles, setOfferedVehicles] = useState<OfferedVehicle[]>([]);
  const [successMessageVisible, setSuccessMessageVisible] = useState(false);
  const [csvFileData, setCsvFileData] = useState("");
  const csvDownloadLink: LegacyRef<any> = useRef();

  useEffect(() => {
    getCurrentOfferingConfigurations().then((response) => {
      let offeredVehicles: OfferedVehicle[] = response.data.flatMap(
        (vehicleConfigurations) =>
          vehicleConfigurations.configurations.map((configurations) => {
            return {
              count: 0,
              vehicleLine: vehicleConfigurations.vehicleLine,
              code: configurations.code,
              body: configurations.body,
            };
          })
      );
      setOfferedVehicles(offeredVehicles);
    });
  }, []);

  const generateRowKey = (offeredVehicle: OfferedVehicle) => {
    return (
      offeredVehicle.vehicleLine + offeredVehicle.code + offeredVehicle.body
    );
  };
  const downloadCSV = () => {
    getSupplementalVehiclesCSV().then((data) => {
      setCsvFileData(data);
      if (csvDownloadLink.current) csvDownloadLink.current.link.click();
    });
  };

  function forceUpdateOfferedVehicles() {
    setOfferedVehicles([...offeredVehicles]);
  }

  function renderCountPlusMinusButtons(offeredVehicle: OfferedVehicle) {
    return (
      <>
        <span>
          <button
            className={"svr-selection-button svr-increment-decrement-buttons"}
            onClick={() => {
              if (offeredVehicle.count > 0) {
                offeredVehicle.count--;
                forceUpdateOfferedVehicles();
              }
            }}>
            -
          </button>
        </span>
        <input
          value={offeredVehicle.count}
          disabled={true}
          className={"svr-input-field"}
        />
        <span>
          <button
            className={"svr-selection-button svr-increment-decrement-buttons"}
            onClick={() => {
              offeredVehicle.count++;
              forceUpdateOfferedVehicles();
            }}>
            +
          </button>
        </span>
      </>
    );
  }

  const tableColumns = [
    new TableColumn({
      title: <TextTranslation textId={"vehicleLineLabel"} />,
      key: "vehicleLine",
    }),
    new TableColumn({
      title: <TextTranslation textId={"bodyLabel"} />,
      key: "body",
    }),
    new TableColumn({
      title: <TextTranslation textId={"PEPCodeLabel"} />,
      key: "code",
    }),
    new TableColumn({
      title: <TextTranslation textId={"amountLabel"} />,
      key: "count",
      render: renderCountPlusMinusButtons,
    }),
  ];

  function isSubmissionValid() {
    return (
      salesCode !== "" &&
      offeredVehicles
        .map((offeredVehicle) => offeredVehicle.count)
        .reduce((sum, count) => sum + count, 0) > 0
    );
  }

  let salesCodeChangeEventHandler = (event: any) => {
    setSalesCode(event.value);
  };

  return (
    <>
      <div className="supplementalVehicleBody">
        <div className="supplementalVehicleHeader">
          <h2 className="supplementalVehicleTitle">
            <TextTranslation textId={"supplementalVehicleRequestLabel"} />
          </h2>
          {(hasAdminPermissions || hasReadAllPermissions) && (
            <>{SVRDownloadButton(downloadCSV, csvFileData, csvDownloadLink)}</>
          )}
        </div>
        <p>
          <TextTranslation textId={"SVRMessage"} />
        </p>
        <SalesCodeSelector
          salesCode={salesCode}
          salesCodeChangeEventHandler={salesCodeChangeEventHandler}
        />
        <Table
          className="rr-table rr-table--alternating-rows rr-table--full-width"
          columns={tableColumns}
          dataSource={offeredVehicles}
          pagination={false}
          rowKey={generateRowKey}
          locale={{emptyText: <TextTranslation textId={"noSVRDataFound"} />}}
        />
        <p>
          <TextTranslation textId={"svrWarningMessage"} />
        </p>
        <div>
          <button
            className="fmc-button"
            disabled={!isSubmissionValid()}
            onClick={() => {
              createSupplementalVehicle({
                salesCode: salesCode,
                vehicles: offeredVehicles,
              }).then(() => setSuccessMessageVisible(true));
            }}>
            <TextTranslation textId={"submitButtonLabel"} />
          </button>
        </div>
        <SuccessPopUp
          isVisible={successMessageVisible}
          setVisibility={setSuccessMessageVisible}
        />
      </div>
    </>
  );
}

function SuccessPopUp(props: {
  isVisible: boolean;
  setVisibility: (visible: boolean) => void;
}) {
  function goHome() {
    window.location.assign("/");
  }

  function startNewRequest() {
    window.location.reload();
  }

  const skin = getSkin();
  return (
    <Modal isOpen={props.isVisible} ariaHideApp={false}>
      <div
        data-brand={skin === Skin.LPIVW ? "lincoln" : "ford"}
        className={
          skin === Skin.LPIVW
            ? "lpivw-font-family__proxima-nova success-message-body"
            : "success-message-body"
        }>
        <div>
          <TextTranslation textId={"SVRSuccessModalMessage"} />
        </div>
        <div>
          <button
            className={"fmc-button fmc-button--outlined"}
            onClick={startNewRequest}>
            <TextTranslation textId={"SVRSubmitNewRequest"} />
          </button>
          &nbsp;&nbsp;
          <button
            className={"fmc-button fmc-button--no-shadow "}
            onClick={goHome}>
            <TextTranslation textId={"SVRGoToHome"} />
          </button>
        </div>
      </div>
    </Modal>
  );
}
