import React from "react";
import "../../styles/ModelE.scss";
import {PortalTitle} from "../../components/home/PortalTitle";
import {ErrorModal} from "../../components/errorModal/ErrorModal";

import {WarningMessage} from "../../components/warningMessage/WarningMessage";

export function ModelEContainer() {
  return (
    <>
      <PortalTitle showToolTip={false} />
      <div className="order-thing">
        <WarningMessage />
      </div>
      <ErrorModal />
    </>
  );
}
