import {ListItemText, Menu, MenuItem} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import {getSkin, Skin} from "../../service/SkinService";
import {Language} from "../../enums/Language";
import {setGlobalState, useGlobalState} from "../../state/GlobalState";

export function LanguageMenu() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentLanguage] = useGlobalState("language");
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const [userDropdownStyleClass, setUserDropdownStyleClass] =
    useState("userDropdown");

  const skin = getSkin();

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuOpen(true);
    setAnchorElement(event.currentTarget);
    if (skin === Skin.LPIVW) {
      setUserDropdownStyleClass("userDropdown lincolnBorder");
    }
  }

  function handleClose() {
    setMenuOpen(false);
    setAnchorElement(null);
    if (skin === Skin.LPIVW) {
      setUserDropdownStyleClass("userDropdown");
    }
  }

  function MenuOptions() {
    return (
      <>
        <MenuItem
          onClick={() => {
            localStorage.setItem("language", Language.EN);
            setGlobalState("language", Language.EN);
          }}>
          <ListItemText>EN</ListItemText>
        </MenuItem>
        {skin === Skin.CVW ? (
          <MenuItem
            onClick={() => {
              localStorage.setItem("language", Language.ES);
              setGlobalState("language", Language.ES);
            }}>
            <ListItemText>ES</ListItemText>
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              localStorage.setItem("language", Language.FR);
              setGlobalState("language", Language.FR);
            }}>
            <ListItemText>FR</ListItemText>
          </MenuItem>
        )}
      </>
    );
  }

  return (
    <div className={userDropdownStyleClass}>
      <button
        onClick={handleClick}
        data-testid={"languageDropDownButton"}
        id={"language-dropdown-button"}>
        <span className={"menu-icon"}>{currentLanguage.toUpperCase()}</span>
      </button>
      <Menu
        data-testid={"menu"}
        open={menuOpen}
        anchorEl={anchorElement}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{horizontal: "right", vertical: "top"}}
        anchorOrigin={{horizontal: "right", vertical: "bottom"}}>
        <MenuOptions />
      </Menu>
    </div>
  );
}
