import {Offering} from "../service/OfferingService";
import {getCountryLocale} from "../components/modelE/Utils";
import {useGlobalState} from "../state/GlobalState";

export function GetFormattedDate(offering: Offering) {
  const [currentLanguage] = useGlobalState("language");

  const countryLocale = getCountryLocale(currentLanguage);

  const formattedStartTime = new Date(offering.startTime).toLocaleString(
    countryLocale,
    {
      month: "short",
      day: "numeric",
    }
  );

  const formattedEndTime = new Date(offering.endTime).toLocaleString(
    countryLocale,
    {
      month: "short",
      day: "numeric",
      year: "numeric",
    }
  );

  return `${formattedStartTime} - ${formattedEndTime}`;
}
