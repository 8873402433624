import "../../styles/faq.scss";
import {FordFAQ} from "../../components/faq/FordFAQ";
import {getSkin, Skin} from "../../service/SkinService";
import * as React from "react";
import {LincolnFAQ} from "../../components/faq/LincolnFAQ";
import {ModelEFAQ} from "../../components/faq/ModelEFAQ";
import {TextTranslation} from "../../utils/Translations";
import {CvwFAQ} from "../../components/faq/CvwFAQ";

export function FAQ() {
  function getFAQ() {
    const skin = getSkin();

    switch (skin) {
      case Skin.LPIVW:
        return <LincolnFAQ />;
      case Skin.MEW:
        return <ModelEFAQ />;
      case Skin.CVW:
      case Skin.IPW:
        return <CvwFAQ />;
      default:
        return <FordFAQ />;
    }
  }

  return (
    <div id={"faq"}>
      <h3 className={"page-header"}>
        <TextTranslation textId={"FAQTitleLabel"} />
      </h3>
      {getFAQ()}
    </div>
  );
}
