import {Table} from "antd";
import React, {LegacyRef, useEffect, useRef, useState} from "react";
import {TableColumn} from "../../models/TableColumn";
import Select from "react-select";
import {getAllRegions} from "../../service/OrderService";
import {
  getCompletedOfferingsForAdmin,
  Offering,
} from "../../service/OfferingService";
import {getOrderHistoryByRegionAndOfferingId} from "../../service/ReportService";
import {CSVLink} from "react-csv";
import {TextTranslation, useTranslation} from "../../utils/Translations";
import {GetFormattedDate} from "../../utils/DateFormatter";

export function OrderHistoryByRegion() {
  const [regions, setRegions] = useState([]);
  const [offerings, setOfferings] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({code: "", name: ""});
  const [selectedOffering, setSelectedOffering] = useState({
    id: "",
    dateRange: "",
  });
  const [downloadButtonEnabled, setDownloadButtonEnabled] = useState(false);
  const [orderHistoryData, setOrderHistoryData] = useState([]);
  const [orderHistoryFileName, setOrderHistoryFileName] = useState("");
  const csvDownloadLink: LegacyRef<any> = useRef();

  const translations = useTranslation([
    "regionPlaceholderText",
    "offeringPlaceholderText",
  ]);

  useEffect(() => {
    getAllRegions()
      .then((regionData) => {
        setRegions(
          regionData.map((region: {regionName: string; regionCode: string}) => {
            return {label: region.regionName, value: region.regionCode};
          })
        );
      })
      .catch((error) => console.log(error.message));
    getCompletedOfferingsForAdmin().then((offeringData) => {
      setOfferings(
        offeringData.map((offering: Offering) => {
          return {label: GetFormattedDate(offering), value: offering._id};
        })
      );
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const downloadButtonHandler = () => {
    getOrderHistoryByRegionAndOfferingId(
      selectedRegion.code,
      selectedOffering.id
    ).then((response) => {
      setOrderHistoryData(response);
      setOrderHistoryFileName(
        "Order History of " +
          selectedRegion.name +
          " for " +
          selectedOffering.dateRange +
          ".csv"
      );
      if (csvDownloadLink.current) csvDownloadLink.current.link.click();
    });
  };

  useEffect(() => {
    setDownloadButtonEnabled(
      selectedRegion.code !== "" && selectedOffering.id !== ""
    );
  }, [selectedRegion, selectedOffering]);

  return (
    <div data-testid={"order-history-by-region"}>
      <Table
        dataSource={[{key: "testStuff"}]}
        className="rr-table rr-table--alternating-rows"
        pagination={false}
        columns={[
          new TableColumn({
            title: <TextTranslation textId={"orderHistoryRegionLabel"} />,
            key: "region",
            render: () => (
              <Select
                noOptionsMessage={() => (
                  <TextTranslation textId={"noOptionsSelector"} />
                )}
                aria-label={"Region"}
                options={regions}
                onChange={(event: any) =>
                  setSelectedRegion({code: event.value, name: event.label})
                }
                placeholder={translations["regionPlaceholderText"]}
              />
            ),
          }),
          new TableColumn({
            title: (
              <TextTranslation textId={"orderHistoryOfferingPeriodLabel"} />
            ),
            key: "offeringPeriod",
            render: () => {
              return (
                <Select
                  noOptionsMessage={() => (
                    <TextTranslation textId={"noOptionsSelector"} />
                  )}
                  aria-label={"Completed Offerings"}
                  options={offerings}
                  onChange={(event: any) =>
                    setSelectedOffering({
                      id: event.value,
                      dateRange: event.label,
                    })
                  }
                  isDisabled={selectedRegion.code === ""}
                  placeholder={translations["offeringPlaceholderText"]}
                />
              );
            },
          }),
          new TableColumn({
            title: (
              <TextTranslation textId={"orderHistoryOfferingDownloadLabel"} />
            ),
            key: "download",
            render: () => {
              return (
                <div>
                  <button
                    className={"fmc-button"}
                    onClick={() => downloadButtonHandler()}
                    disabled={!downloadButtonEnabled}>
                    <TextTranslation textId={"orderHistoryDownloadButton"} />
                  </button>
                  <CSVLink
                    data-testid={"csv-download-link"}
                    data={orderHistoryData}
                    filename={orderHistoryFileName}
                    ref={csvDownloadLink}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                </div>
              );
            },
          }),
        ]}
      />
    </div>
  );
}
