import {getHttpClient} from "./API";
import {ApiUrls} from "../enums/ApiUrls";
import {Country} from "../enums/Country";

export async function getAvailableVehicleLines(): Promise<string[]> {
  let response = await getHttpClient().get(ApiUrls.availableVehicleLines);
  return response.data;
}
export async function getCompletedOfferingsForAdmin() {
  const response = await getHttpClient().get(ApiUrls.completedOfferings);
  return response.data;
}

export async function getCompletedOfferingsForDealer(salesCode: string) {
  const response = await getHttpClient().get(
    ApiUrls.completedOfferings + `/${salesCode}`
  );
  return response.data;
}

export async function getCompletedAndActiveOfferings() {
  const response = await getHttpClient().get(
    ApiUrls.completedAndActiveOfferings
  );
  return response.data;
}

export async function getModelEHistoricalRebillingData() {
  return await getHttpClient().get(ApiUrls.getModelERebillingReport);
}

export async function getAvailableVehicleReportData() {
  return await getHttpClient().get(ApiUrls.getModelEAvailableVehicleReport);
}

export async function getCompletedOfferingsForAllCountries(): Promise<
  Offering[]
> {
  const response = await getHttpClient().get(
    ApiUrls.completedOfferingsForAllCountries
  );
  return response.data.sort((a: {country: string}, b: {country: any}) =>
    a.country.localeCompare(b.country)
  );
}

export async function postOfferingToRebilling(offeringId: string) {
  const response = await getHttpClient().post(
    `${ApiUrls.rebillOffering}/${offeringId}`
  );
  return response.status;
}

export type Offering = {
  _id: string;
  startTime: string;
  endTime: string;
  reallocationEndTime: string;
  dateRange: string;
  wholesaleType: string;
  country: Country;
  isRebilled: boolean;
};
