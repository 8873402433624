import {TextTranslation} from "../../utils/Translations";

export interface FAQKeyPair {
  questionKey: string;
  answerKey: string;
}

export function FaqElement(props: {
  faqKeyPair: FAQKeyPair;
  keyAddedToSilenceReactWarningInConsole: any;
}) {
  return (
    <>
      <p className={"question  common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"QuestionFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation
            textId={props.faqKeyPair.questionKey}></TextTranslation>
        </span>
      </p>
      <p className={"answer common-faq-row"}>
        <span className={"lead-letter"}>
          <TextTranslation textId={"AnswerFirstLetter"} />:{" "}
        </span>
        <span className={"faq-text"}>
          <TextTranslation
            textId={props.faqKeyPair.answerKey}></TextTranslation>
        </span>
      </p>
    </>
  );
}
