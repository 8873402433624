import {useGlobalState} from "../state/GlobalState";
import {getSkin, Skin} from "../service/SkinService";
import React from "react";
import {Language} from "../enums/Language";

export function TextTranslation(props: {textId: string}) {
  const [language] = useGlobalState("language");
  const translatedTextAsString = useTranslation([props.textId]);
  const translatedTextFormatted =
    baseContentFormattedMap.get(language)?.get(props.textId) ??
    getSiteSpecificTextFormatted(language, props.textId);
  if (translatedTextFormatted) {
    return (
      <div dangerouslySetInnerHTML={{__html: translatedTextFormatted}}></div>
    );
  }
  return <>{translatedTextAsString[props.textId]}</>;
}

export function useTranslation(textIds: string[]): {[key: string]: string} {
  const [language] = useGlobalState("language");

  const translations = textIds.map((textId): {[key: string]: string} => {
    const translatedText =
      baseContentMap.get(language)?.get(textId) ??
      getSiteSpecificText(language, textId);
    if (translatedText) {
      return {[textId]: translatedText};
    }
    return {[textId]: textId};
  });

  return translations.reduce((acc, val) => ({...acc, ...val}), {});
}

function getTranslationFromSiteContentMap(
  contentMap: Map<string, Map<string, any>>,
  language: string,
  textId: string
) {
  const languageMap = contentMap.get(language) ?? contentMap.get(Language.EN);
  return languageMap?.get(textId);
}

function getSiteSpecificText(language: string, textId: string) {
  const skin = getSkin();
  switch (skin) {
    case Skin.MAP:
      return getTranslationFromSiteContentMap(mapContentMap, language, textId);
    case Skin.CVW:
      return getTranslationFromSiteContentMap(cvwContentMap, language, textId);
    case Skin.IPW:
      return getTranslationFromSiteContentMap(ipwContentMap, language, textId);
    case Skin.LPIVW:
      return getTranslationFromSiteContentMap(
        lpivwContentMap,
        language,
        textId
      );
    case Skin.MEW:
      return getTranslationFromSiteContentMap(mewContentMap, language, textId);
  }
}

function getSiteSpecificTextFormatted(language: string, textId: string) {
  const skin = getSkin();
  switch (skin) {
    case Skin.MAP:
      return getTranslationFromSiteContentMap(
        mapContentMapFormatted,
        language,
        textId
      );
    case Skin.CVW:
      return getTranslationFromSiteContentMap(
        cvwContentMapFormatted,
        language,
        textId
      );
    case Skin.IPW:
      return getTranslationFromSiteContentMap(
        ipwContentMapFormatted,
        language,
        textId
      );
    case Skin.LPIVW:
      return getTranslationFromSiteContentMap(
        lpivwContentMapFormatted,
        language,
        textId
      );
    case Skin.MEW:
      return getTranslationFromSiteContentMap(
        mewContentMapFormatted,
        language,
        textId
      );
  }
}

const baseContentEnglish = new Map<string, any>([
  ["AnswerFirstLetter", "A"],
  [
    "DuringReallocationPeriod",
    "During the reallocation period, any vehicles reallocated to the selected sales code will appear here.",
  ],
  ["EnterSalesCodeLabel", "Enter sales code to receive vehicle:"],
  ["FAQTitleLabel", "FREQUENTLY ASKED QUESTIONS (FAQ)"],
  ["LimitedActionPeriod", "Limited action period ends"],
  [
    "NoCurrentOfferings",
    "You have no current offerings for this vehicle line. Please select another vehicle line.",
  ],
  [
    "NoVehicleForSalesCode",
    "Selected sales code has no allocated vehicles for current offering.",
  ],
  ["PEPCodeLabel", "PEP Code"],
  ["QuestionFirstLetter", "Q"],
  ["ReallocatingLabel", "Reallocating"],
  [
    "ReallocationPeriod",
    "Reallocation Period The standard offering window has now closed. If a vehicle is reallocated to your dealership during the reallocation period, you will be able to accept or reject that vehicle.",
  ],
  ["ReallocationPeriodLabel", "REALLOCATION PERIOD"],
  ["SVRGoToHome", "Go to Home"],
  [
    "SVRMessage",
    "If given the option to receive more vehicles than selected in the allocation, what vehicles would you like to request and how many?",
  ],
  ["SVRSubmitNewRequest", "Submit new request"],
  [
    "SVRSuccessModalMessage",
    "Your supplemental vehicle was submitted successfully.",
  ],
  [
    "SelectVehicleLine",
    "Please select a vehicle line to see vehicle offerings.",
  ],
  ["acceptLabel", "Accept"],
  ["acceptedLabel", "Accepted"],
  ["adminDashboard", "Admin Dashboard"],
  ["adminDownloadReportRow", "Download Report"],
  ["adminDownloadReports", "Download Reports"],
  ["adminMenuLabel", "Admin"],
  ["adminOfferPeriod", "Offer Period"],
  [
    "adminReportDescription",
    "Download or preview reports of accepted, rejected and allocated vehicles in each offer period.",
  ],
  ["allocationMethodologyLabel", "ALLOCATION METHODOLOGY"],
  ["amountLabel", "Amount"],
  ["approveButtonLabel", "Approve"],
  ["attentionLabel", "ATTENTION"],
  ["bodyLabel", "Body"],
  ["cancelButton", "Cancel"],
  ["closeButtonLabel", "Close"],
  ["codeLabel", "Code"],
  ["confirmationButton", "Yes, I'm sure"],
  [
    "confirmationEmailWithDefaultEmailPart1",
    "Your confirmation will be sent to ",
  ],
  [
    "confirmationEmailWithDefaultEmailPart2",
    " as well as the email address you enter below:",
  ],
  [
    "confirmationRequestingEmail",
    "Your confirmation will be sent to the email address you enter below:",
  ],
  ["contactUsCDSIDInput", "Enter WSLX ID or CDSID"],
  ["contactUsCDSIDWarning", "Please provide a WSLX ID or CDSID"],
  [
    "contactUsDescription",
    "Please provide relevant information pertaining to your issue (e.g. the browser, if this was previously working for you, and what steps you may have already taken). Please be descriptive.",
  ],
  ["contactUsDescriptionWarning", "Please provide a description of your issue"],
  ["contactUsEmail", "Enter your contact email"],
  [
    "contactUsHeader2",
    "Please fill out the form below to help us address your issue quickly. Required fields are marked with a ",
  ],
  ["contactUsMenuLabel", "Contact Us"],
  ["contactUsSalesCode", "Enter sales code"],
  ["contactUsSalesCodeWarning", "Please provide a sales code"],
  ["continueLabel", "Continue"],
  ["dealerFAQMenuLabel", "Dealer FAQ"],
  [
    "dealerOrderWindowClosed",
    "The dealer order window is closed. No vehicle offerings available at this time.",
  ],
  ["dealerSelection", "Dealer Selection"],
  ["dealershipNameLabel", "Dealership Name"],
  [
    "deleteConfirmation",
    "Are you sure you want to delete the warning message?",
  ],
  [
    "doNotRecognizeEmailLabel",
    "If you don't recognize this email and wish to change it, select ‘Change my Profile’ in your ",
  ],
  ["downloadCSVLabel", "Download CSV"],
  [
    "emailWillBeUpdatedLabel",
    ". The email will be updated the next time you log in to the portal.",
  ],
  ["engineLabel", "Engine"],
  ["enterEmailHereLabel", "enter email here"],
  ["errorModalLabel", "Sorry, an error occurred. Please try again later."],
  ["exportFullReportButton", "Export Full Report"],
  ["exportRebillingReportButton", "Export Rebilling Report"],
  ["greetingText", "Hi, "],
  ["homeText", "Home"],
  ["lastModifiedBy", "Last modified by "],
  ["logoutMenuLabel", "Logout"],
  ["noOptionsSelector", "No options"],
  ["noPastOfferings", "No past offerings for selected sales code"],
  [
    "noPermissionForSalesCode",
    "You don't have permission to submit orders for this sales code.",
  ],
  ["noSVRDataFound", "No data"],
  ["offerEndsMessage", "Offer ends "],
  ["offerNotYetStarted", "Offer not yet started"],
  ["offerStarts", "Offer starts:"],
  ["offeringPlaceholderText", "OFFER PERIOD"],
  ["offeringStatusLabel", "OFFERING STATUS"],
  ["onLabel", " on "],
  ["optionalFeaturesLabel", "Included Features"],
  ["optionalPackagesLabel", "Included Packages"],
  ["optionsLabel", "Options"],
  [
    "orderGridDefaultMessage",
    "Please select a Sales Code. If a Sales Code does not appear in the list you do not have permission to submit wholesale orders.",
  ],
  ["orderHistoryDownloadButton", "Download"],
  ["orderHistoryDownloadReportLabel", "Export Offer Period Report"],
  ["orderHistoryFor", " for "],
  ["orderHistoryOf", "Order History of "],
  ["orderHistoryOfferingDownloadLabel", "Download Offer Period Report"],
  ["orderHistoryOfferingPeriodLabel", "Offer Period"],
  ["orderHistoryRegionLabel", "Region"],
  ["orderHistorySalesCodeLabel", "Sales Code"],
  ["orderHistoryViewReportLabel", "Preview Offer Period Report"],
  ["packagesLabel", "Packages"],
  ["paintLabel", "Paint"],
  ["permissionCDSPlaceholder", "CDSID"],
  ["permissionLookup", "Permission Lookup"],
  ["reallocateLabel", "Reallocate"],
  ["refreshPage", "Please refresh the page to see the changes."],
  ["regionPlaceholderText", "REGION"],
  ["rejectLabel", "Reject"],
  ["rejectedLabel", "Rejected"],
  ["salesCodePlaceholderText", "SALES CODE"],
  ["searchButton", "Search"],
  ["selectOfferingPeriodPlaceholderText", "SELECT OFFERING PERIOD"],
  ["selectSalesCodePlaceholderText", "Select a sales code"],
  ["selectSalesCodeWarningMessage", "Please select a sales code"],
  ["selectVehicleLinePlaceholderText", "Select a vehicle line"],
  ["selectVehicleLineWarningMessage", "Please select a vehicle line"],
  ["statusLabel", "Status"],
  ["submitButtonLabel", "Submit Request"],
  [
    "submitButtonTooltip",
    "Selections need to take place before vehicle submission",
  ],
  ["submitReallocationLabel", "Submit Reallocation"],
  ["supplementalVehicleRequestLabel", "Supplemental Vehicle Request"],
  [
    "svrWarningMessage",
    "Please note that submitting a request does not guarantee the vehicles will be available to be delivered. You will be notified via email of the status of your request.",
  ],
  ["trimLabel", "Trim"],
  ["urgentMessageSuccess", "Your urgent message was submitted successfully."],
  ["userLabel", "User"],
  ["userProfileLabel", "User Profile"],
  ["vehicleLineLabel", "Vehicle Line"],
  ["vehicleOfferingLabel", "VEHICLE OFFERING"],
  ["vehiclesLeftToReviewText", "vehicles left to review"],
  [
    "vehiclesMustBeRejected",
    "Vehicles must be rejected before they can be reallocated",
  ],
  ["vinLabel", "VIN"],
  ["warningMessageButton", "Submit Message"],
  ["warningMessageHeader", "URGENT MESSAGING"],
  ["warningMessageMenuLabel", "Set Warning Message"],
  [
    "warningMessagePlaceholder",
    "Please enter the contents of the Urgent Message as it should appear to Portal users.",
  ],
]);
const baseContentFrench = new Map<string, any>([
  ["AnswerFirstLetter", "R"],
  [
    "DuringReallocationPeriod",
    "Durant la période de réattribution, tout véhicule réattribué au code de vente sélectionné apparaîtra ici.",
  ],
  [
    "EnterSalesCodeLabel",
    "Entrez le code de vente pour recevoir le véhicule :",
  ],
  ["FAQTitleLabel", "FOIRE AUX QUESTIONS (FAQ)"],
  ["LimitedActionPeriod", "La période d’action limitée se termine le :"],
  [
    "NoCurrentOfferings",
    "Vous n'avez pas d'offres actuelles pour cette gamme de véhicules. Veuillez sélectionner une autre gamme de véhicules.",
  ],
  [
    "NoVehicleForSalesCode",
    "Le code de vente sélectionné n’est associé à aucun véhicule attribué pour l’offre en cours.",
  ],
  ["PEPCodeLabel", "Code de groupe d’équipements préférés"],
  ["QuestionFirstLetter", "Q"],
  ["ReallocatingLabel", "Réattribution"],
  [
    "ReallocationPeriod",
    "Période de réattribution :la période de l’offre standard est maintenant terminée. Si un véhicule est réattribué à votre concessionnaire durant la période de réattribution, vous pourrez accepter ou rejeter ce véhicule.",
  ],
  ["ReallocationPeriodLabel", "PÉRIODE DE RÉATTRIBUTION"],
  ["SVRGoToHome", "Aller à l’accueil"],
  [
    "SVRMessage",
    "Si vous avez la possibilité de recevoir plus de véhicules que ceux sélectionnés dans le cadre de l’allocation, quels véhicules souhaiteriez-vous demander, et combien d’unités?",
  ],
  ["SVRSubmitNewRequest", "Soumettre une nouvelle demande"],
  [
    "SVRSuccessModalMessage",
    "Votre demande de véhicules supplémentaires a bien été soumise.",
  ],
  [
    "SelectVehicleLine",
    "Veuillez sélectionner une gamme de véhicules pour voir les offres de véhicules",
  ],
  ["acceptLabel", "Accepter"],
  ["acceptedLabel", "Acceptée"],
  ["adminDashboard", "Tableau de bord de l’administrateur"],
  ["adminDownloadReportRow", "Télécharger des rapport"],
  ["adminDownloadReports", "Télécharger des rapports"],
  ["adminMenuLabel", "Admin"],
  ["adminOfferPeriod", "Période de l’offre"],
  [
    "adminReportDescription",
    "Téléchargez ou affichez un aperçu des rapports sur les véhicules acceptés, refusés et alloués pour chaque période d’offre.",
  ],
  ["allocationMethodologyLabel", "MÉTHODOLOGIE D’ALLOCATION"],
  ["amountLabel", "Quantité"],
  ["approveButtonLabel", "Approuver"],
  ["attentionLabel", "ATTENTION"],
  ["bodyLabel", "Modèle"],
  ["cancelButton", "Annuler"],
  ["closeButtonLabel", "Fermer"],
  ["codeLabel", "Code"],
  ["confirmationButton", "Oui"],
  [
    "confirmationEmailWithDefaultEmailPart1",
    "Votre confirmation sera envoyée à ",
  ],
  [
    "confirmationEmailWithDefaultEmailPart2",
    ' ainsi qu’à l’adresse de courriel que vous avez indiquée ci-"dessous", ',
  ],
  [
    "confirmationRequestingEmail",
    "Votre confirmation sera envoyée à l’adresse de courriel que vous avez indiquée ci-dessous:",
  ],
  [
    "contactDealerAdmin",
    "Si vous croyez qu’il s’agit d’une erreur, veuillez communiquer avec l’administrateur de votre concessionnaire.",
  ],
  ["contactUsCDSIDInput", "Entrez votre identifiant WSLx ou CDS"],
  ["contactUsCDSIDWarning", "Veuillez entrer votre identifiant WSLx ou CDS"],
  [
    "contactUsDescription",
    "Veuillez décrire votre problème en incluant les principaux éléments (p. ex., le navigateur, s’il fonctionnait auparavant, les étapes que vous avez prises jusqu’ici, etc.). Soyez le plus précis possible.",
  ],
  ["contactUsDescriptionWarning", "Veuillez décrire votre problème"],
  ["contactUsEmail", "Entrez votre adresse courriel"],
  [
    "contactUsHeader2",
    "Veuillez remplir le formulaire ci-dessous pour nous aider à régler votre problème rapidement. Les champs obligatoires sont marqués du symbole ",
  ],
  ["contactUsMenuLabel", "Nous joindre"],
  ["contactUsSalesCode", "Entrez le code de vente"],
  ["contactUsSalesCodeWarning", "Veuillez entrer un code de vente"],
  ["continueLabel", "Continuer"],
  ["dealerFAQMenuLabel", "FAQ pour les concessionnaires"],
  [
    "dealerOrderWindowClosed",
    "La période de commande des concessionnaires est terminée. Il n’y a aucune offre sur les véhicules en ce moment.",
  ],
  ["dealerSelection", "Sélection du concessionnaire"],
  ["dealershipNameLabel", "Nom du concessionnaire"],
  [
    "deleteConfirmation",
    "Voulez-vous vraiment supprimer le message d’avertissement?",
  ],
  [
    "doNotRecognizeEmailLabel",
    "Si vous ne reconnaissez pas cette adresse et que vous souhaitez la modifier, sélectionnez Modifier mon profil dans votre ",
  ],
  ["downloadCSVLabel", "Télécharger au format CSV"],
  [
    "emailWillBeUpdatedLabel",
    "; l’adresse sera mise à jour lors de votre prochaine connexion au portail.",
  ],
  ["engineLabel", "Moteur"],
  ["enterEmailHereLabel", "l’adresse de courriel"],
  [
    "errorModalLabel",
    "Nous sommes désolés, une erreur s’est produite. Veuillez réessayer plus tard.",
  ],
  ["exportFullReportButton", "Exporter le rapport intégral"],
  ["exportRebillingReportButton", "Exporter le rapport de refacturation"],
  [
    "faqFordAcceptPortal",
    "Qui peut accepter l’allocation dans le portail chez le concessionnaire? Comment puis-je y avoir accès?",
  ],
  [
    "faqFordAcceptedBeRebilled",
    "Quand refacturera-t-on mes véhicules acceptés à mon concessionnaire?",
  ],
  [
    "faqFordAcceptedBeRebilledA",
    "Les véhicules acceptés seront refacturés au concessionnaire dans les dix (10) jours ouvrables suivant la fermeture du portail. ",
  ],
  [
    "faqFordAmendOrders",
    "Puis-je modifier des articles sur mes commandes acceptées?",
  ],
  [
    "faqFordAmendOrdersA1",
    "Réapprovisionnement rapide – Les commandes deviennent visibles dans WBDO une fois que les véhicules ont été acceptés et refacturés. Les concessionnaires peuvent à ce moment ajuster certaines caractéristiques comme la peinture et l’équipement en option installé par le concessionnaire avant la fin de la période de modification des commandes.",
  ],
  [
    "faqFordAmendOrdersA2",
    "Véhicules configurés pour la vente en gros – Les commandes deviendront visibles dans WBDO une fois que les véhicules auront été acceptés et refacturés. Les concessionnaires ne pourront plus ajuster les caractéristiques par la suite.",
  ],
  [
    "faqFordDealershipEarned",
    "Le concessionnaire n’a accumulé aucun (0) véhicule de la gamme affichée pendant la période de l’offre. Assurez-vous de vérifier toutes les gammes de véhicules à l’aide du menu déroulant.",
  ],
  [
    "faqFordFIIAuthority",
    "Les signataires autorisés des concessionnaires doivent accepter le réapprovisionnement rapide et l’allocation de véhicules configurés pour la vente en gros. Les personnes doivent avoir la permission « Order Management:Rapid_Replenishment » pour conclure la vente en gros dans le portail. Veuillez communiquer avec votre administrateur pour obtenir de l’aide.",
  ],
  [
    "faqFordFindAccepted",
    "Comment puis-je trouver dans WBDO mes véhicules pour lesquels un réapprovisionnement rapide a été accepté?",
  ],
  [
    "faqFordFindAcceptedA1",
    "Réapprovisionnement rapide – Dans l’onglet des commandes, cliquez sur le menu déroulant « Affichage », puis sélectionnez « Réapprovisionnement rapide ».",
  ],
  [
    "faqFordFindAcceptedA2",
    "Véhicules configurés pour la vente en gros – Appliquez le filtre Gamme de véhicules par défaut, puis filtrez les résultats associés au code de priorité 80.",
  ],
  [
    "faqFordITTroubles",
    "J’ai des problèmes techniques m’empêchant d’accéder au site.",
  ],
  [
    "faqFordITTroublesA",
    "Veuillez essayer un autre navigateur ou vider la mémoire cache. De plus, seules les personnes pouvant approuver le réapprovisionnement rapide pour la vente en gros (signataires autorisés) peuvent accéder à l’offre. Si vous avez besoin d’assistance supplémentaire, veuillez utiliser la fonction « Communiquez avec nous » dans le menu déroulant du portail.",
  ],
  [
    "faqFordNoVehicleOfferings",
    "Aucune offre de véhicule ne s’affiche quand j’entre mon code de vente. Qu’est-ce que cela signifie?",
  ],
  ["greetingText", "Bonjour, "],
  ["homeText", "Accueil"],
  ["lastModifiedBy", "Dernière modification par "],
  ["logoutMenuLabel", "Fermer la session"],
  ["noOptionsSelector", "Aucune option"],
  ["noPastOfferings", "Aucune offre antérieure"],
  [
    "noPermissionForSalesCode",
    "Vous n’avez pas la permission pour soumettre des commandes pour ce code de vente.»",
  ],
  ["noSVRDataFound", "Aucune donnée"],
  ["offerEndsMessage", 'L’offre expire "le", '],
  ["offerNotYetStarted", "L’offre n’est pas encore commencée"],
  ["offerStarts", "L’offre débute:"],
  ["offeringPlaceholderText", "PÉRIODE D'OFFRE"],
  ["offeringStatusLabel", "ÉTAT DE L’OFFRE"],
  ["onLabel", " le "],
  ["optionalFeaturesLabel", "Caractéristiques en option"],
  ["optionalPackagesLabel", "Ensembles en option"],
  ["optionsLabel", "Options"],
  [
    "orderGridDefaultMessage",
    "Veuillez sélectionner un code de vente. Si un code de vente ne s’affiche pas dans la liste, vous n’avez pas la permission de soumettre des commandes en gros.",
  ],
  ["orderHistoryDownloadButton", "Télécharger"],
  [
    "orderHistoryDownloadReportLabel",
    "Exporter le rapport de la période d'offre",
  ],
  ["orderHistoryFor", " pour la période du "],
  ["orderHistoryOf", "Historique des commandes de "],
  [
    "orderHistoryOfferingDownloadLabel",
    "Télécharger le rapport de la période d'offre",
  ],
  ["orderHistoryOfferingPeriodLabel", "Période d'offre"],
  [
    "orderHistoryPageDescription",
    "Téléchargez les rapports des véhicules acceptés, refusés et alloués pour chaque période d'offre.",
  ],
  ["orderHistoryPageSubject", "HISTORIQUE DES COMMANDES"],
  ["orderHistoryRegionLabel", "Région"],
  ["orderHistorySalesCodeLabel", "Code de vente"],
  ["orderHistoryViewReportLabel", "Aperçu du rapport de la période d'offre"],
  ["packagesLabel", "Ensembles"],
  ["paintLabel", "Peinture"],
  ["permissionCDSPlaceholder", "Identité CDS"],
  ["permissionLookup", "Recherche de permission"],
  ["reallocateLabel", "Réattribuer"],
  ["refreshPage", "Veuillez actualiser la page pour voir les modifications."],
  ["regionPlaceholderText", "RÉGION"],
  ["rejectLabel", "Rejeter"],
  ["rejectedLabel", "Rejetée"],
  ["salesCodePlaceholderText", "CODE de VENTE"],
  ["searchButton", "Rechercher"],
  ["selectOfferingPeriodPlaceholderText", "Sélectionner la période de l’offre"],
  ["selectSalesCodePlaceholderText", "Sélectionner un code de vente"],
  ["selectSalesCodeWarningMessage", "Veuillez sélectionner un code de vente"],
  ["selectVehicleLinePlaceholderText", "Sélectionner une gamme de véhicules"],
  [
    "selectVehicleLineWarningMessage",
    "Veuillez sélectionner une gamme de véhicules",
  ],
  ["statusLabel", "État"],
  ["submitButtonLabel", "Soumettre la demande"],
  [
    "submitButtonTooltip",
    "Vous devez faire vos sélections avant de soumettre le véhicule",
  ],
  ["submitReallocationLabel", "Soumettre Réattribuer"],
  ["supplementalVehicleRequestLabel", "Demandes de véhicules supplémentaires"],
  [
    "svrWarningMessage",
    "Veuillez noter que le fait de soumettre une demande ne garantit pas que tous les véhicules demandés pourront vous être livrés. Vous serez informé par courriel de l’état de votre demande.",
  ],
  ["trimLabel", "Version"],
  ["urgentMessageSuccess", "Votre message urgent a bien été soumis."],
  ["userLabel", "Utilisateur"],
  ["userProfileLabel", "profil d’utilisateur"],
  ["vehicleLineLabel", "Gamme de véhicules"],
  ["vehicleOfferingLabel", "OFFRE DE VÉHICULES"],
  ["vehiclesLeftToReviewText", "offre(s) de yy à examiner"],
  [
    "vehiclesMustBeRejected",
    "Les véhicules doivent être rejetés avant de pouvoir être réattribués",
  ],
  ["vinLabel", "NIV"],
  ["warningMessageButton", "Soumettre le message"],
  ["warningMessageHeader", "MESSAGE URGENT"],
  ["warningMessageMenuLabel", "Régler un message d’avertissement"],
  [
    "warningMessagePlaceholder",
    "Veuillez entrer le message urgent tel qu’il doit s’afficher pour les utilisateurs du portail.",
  ],
]);
const baseContentSpanish = new Map<string, any>([
  ["AnswerFirstLetter", "R"],
  [
    "DuringReallocationPeriod",
    "Durante el período de reasignación, cualquier vehículo reasignado al número de distribuidor seleccionado aparecerá aquí.",
  ],
  [
    "EnterSalesCodeLabel",
    "Ingrese el número de distribuidor para recibir el vehículo:",
  ],
  ["FAQTitleLabel", "Preguntas Frecuentes (FAQ)"],
  ["LimitedActionPeriod", "El periodo de acción limitada finaliza el:"],
  [
    "NoCurrentOfferings",
    "No hay ofertas disponibles para esta línea de vehículo. Por favor, seleccione otra línea de vehículo.",
  ],
  [
    "NoVehicleForSalesCode",
    "El número de distribuidor seleccionado no tiene vehículos asignados para la oferta actual.",
  ],
  ["PEPCodeLabel", "Código PEP"],
  ["QuestionFirstLetter", "P"],
  ["ReallocatingLabel", "Reasignando"],
  [
    "ReallocationPeriod",
    "Periodo de Reasignación: El plazo para la oferta estándar ha finalizado. Si se reasigna un vehículo a su concesionario durante el período de reasignación, podrá aceptarlo o rechazarlo.",
  ],
  ["ReallocationPeriodLabel", "Periodo de reasignación"],
  ["SVRGoToHome", "Ir a Inicio"],
  [
    "SVRMessage",
    "Si se le ofreciera la opción de recibir más vehículos de los seleccionados en la asignación, ¿cuáles y cuántos vehículos solicitaría?",
  ],
  ["SVRSubmitNewRequest", "Subir nueva solicitud"],
  [
    "SVRSuccessModalMessage",
    "Su solicitud de vehículo suplementario se ha enviado correctamente.",
  ],
  [
    "SelectVehicleLine",
    "Por favor, seleccione una línea de vehículo para ver la oferta.",
  ],
  ["acceptLabel", "Aceptar"],
  ["acceptedLabel", "Aceptado"],
  ["adminDashboard", "Admin Dashboard"],
  ["adminDownloadReportRow", "Descargar Reporte"],
  ["adminDownloadReports", "Descargar Reportes"],
  ["adminMenuLabel", "Admin"],
  ["adminOfferPeriod", "Periodo de Oferta"],
  [
    "adminReportDescription",
    "Descargue o previsualice los informes de vehículos aceptados, rechazados y asignados para cada período de oferta",
  ],
  ["allocationMethodologyLabel", "Metodología de Asignaciones"],
  ["amountLabel", "Cantidad"],
  ["approveButtonLabel", "Aprobar"],
  [
    "approveButtonMessage",
    "* Sus selecciones no se guardarán a menos que haga clic en el botón Aprobar. Los cambios no guardados se perderán.",
  ],
  ["attentionLabel", "ATENCIÓN"],
  ["bodyLabel", "Carrocería"],
  ["cancelButton", "Cancelar"],
  ["closeButtonLabel", "Cerrar"],
  ["codeLabel", "Código PEP"],
  ["confirmationButton", "Sí, estoy seguro"],
  [
    "confirmationEmailWithDefaultEmailPart1",
    "Su confirmación será enviada a  ",
  ],
  [
    "confirmationEmailWithDefaultEmailPart2",
    " así como la dirección de correo electrónico que ingrese a continuación:",
  ],
  [
    "confirmationRequestingEmail",
    'Su confirmación será enviada al correo electrónico que ingrese a "continuación", ',
  ],
  ["contactUsCDSIDInput", "Ingrese su WSLX ID o CDSID"],
  ["contactUsCDSIDWarning", "Por favor, ingrese su WSLX ID o CDSID"],
  [
    "contactUsDescription",
    "Por favor, proporcione información relevante relacionada con su problema (por ejemplo, el navegador que está utilizando, si esto funcionaba anteriormente y qué pasos ya ha realizado).",
  ],
  ["contactUsDescriptionWarning", "Por favor, describa su problema"],
  ["contactUsEmail", "Ingrese su correo electrónico"],
  [
    "contactUsHeader1",
    "¿Tiene problemas con el portal? Para dudas sobre permisos o acceso, consulte las preguntas frecuentes para distribuidores o contacte a su gerente de zona.",
  ],
  [
    "contactUsHeader2",
    "Por favor, complete el formulario a continuación para ayudarnos a resolver su problema rápidamente. Los campos obligatorios están marcados con un ",
  ],
  ["contactUsMenuLabel", "Contáctanos"],
  ["contactUsSalesCode", "Ingrese su número de distribuidor"],
  ["contactUsSalesCodeWarning", "Por favor ingrese su número de distribuidor"],
  ["continueLabel", "Continuar"],
  ["dealerFAQMenuLabel", "Preguntas Frecuentes"],
  [
    "dealerOrderWindowClosed",
    "El período de pedidos para distribuidores está cerrado. No hay ofertas de vehículos disponibles en este momento.",
  ],
  ["dealerSelection", "Selección del Distribuidor"],
  ["dealershipNameLabel", "Nombre del Distribuidor"],
  [
    "deleteConfirmation",
    "¿Está seguro de que desea eliminar el mensaje de advertencia?",
  ],
  [
    "doNotRecognizeEmailLabel",
    "Si no reconoce este correo electrónico y desea cambiarlo, seleccione ‘Cambiar mi perfil’ en su ",
  ],
  ["downloadCSVLabel", "Descargar CSV"],
  [
    "downloadCSVTooltip",
    "Descargue el reporte/formato de solicitudes de vehículos suplementarios para la oferta actual.",
  ],
  [
    "emailWillBeUpdatedLabel",
    ". El correo electrónico estará actualizado la siguiente vez que inicie sesión en el portal.",
  ],
  ["engineLabel", "Motor"],
  ["enterEmailHereLabel", "Ingrese su correo electrónico aquí"],
  [
    "errorModalLabel",
    "Lo sentimos, se produjo un error. Por favor, inténtelo de nuevo más tarde.",
  ],
  ["exportFullReportButton", "Exportar Reporte Completo"],
  ["exportRebillingReportButton", "Exportar Reporte de Refacturación"],
  [
    "faqFordAcceptPortal",
    "¿Quién puede aceptar las asignaciones en el portal en el distribuidor? ¿Cómo puedo obtener acceso?",
  ],
  [
    "faqFordAcceptedBeRebilled",
    "¿Cuándo se van a facturar las unidades que acepté?",
  ],
  [
    "faqFordAcceptedBeRebilledA",
    "Las unidades aceptadas se van a refacturar al distribuidor dentro de los diez (10) días hábiles posteriores al cierre del portal. Consulte la publicación del Centro de Mensajes para Concesionarios FMC para conocer las fechas exactas.",
  ],
  ["faqFordAmendOrders", "¿Puedo modificar algo en mis órdenes aceptadas?"],
  [
    "faqFordAmendOrdersA1",
    "Rapid Replenishment – Una vez aceptadas y refacturadas las unidades, las órdenes estarán visibles en WBDO. En ese momento, los distribuidores podrán ajustar ciertas características, como el color de la pintura y las opciones instaladas en la concesionario, antes de la fecha límite para modificarlas.",
  ],
  [
    "faqFordAmendOrdersA2",
    "Órdenes Vehículos Preconfigurados/CVW – Una vez aceptadas y refacturadas las unidades, las órdenes serán visibles en WBDO. En ese momento, los concesionarios ya no podrán modificar las características.",
  ],
  [
    "faqFordDealershipEarned",
    "El distribuidor obtuvo cero (0) unidades para el período de oferta de la línea de vehículos mostrada. Asegúrese de verificar todas las líneas de vehículos utilizando el menú desplegable de líneas de vehículos.",
  ],
  [
    "faqFordFIIAuthority",
    'El personal con autoridad FII en el distribuidor debe aceptar las asignaciones para las órdenes de vehículos preconfiguradas. Para realizar pedidos en el portal, se necesita el permiso “Manejo de "órdenes",Órdenes Vehículos Preconfigurados (CVW)”. Por favor, contacte a su administrador si necesita ayuda.',
  ],
  [
    "faqFordFindAccepted",
    "¿Cómo puedo encontrar mis unidades de Rapid Replenishment aceptadas en WBDO?",
  ],
  [
    "faqFordFindAcceptedA1",
    'Rapid Replenishment – Utilice el menú desplegable “Mostrar” en la pestaña de Órdenes para seleccionar "Rapid Replenishment"',
  ],
  [
    "faqFordFindAcceptedA2",
    "CVW – Filtrar la Línea de Vehículo, filtrar el código de prioridad 80",
  ],
  [
    "faqFordITTroubles",
    "Tengo problemas con el funcionamiento del sitio web (IT)",
  ],
  [
    "faqFordITTroublesA",
    "Por favor, intente con otro navegador o borre su caché. Además, únicamente las personas autorizadas para aprobar las asignaciones en el mayoreo (FII) pueden acceder a la oferta. Si necesita más ayuda, utilice la función “Contáctanos” en el sitio del portal a través del menú desplegable.",
  ],
  [
    "faqFordNoVehicleOfferings",
    "No aparecen ofertas de vehículos cuando ingreso mi número de distribuidor. ¿Qué significa esto?",
  ],
  ["greetingText", "Hola, "],
  ["homeText", "Inicio"],
  ["lastModifiedBy", "Última modificación realizada por "],
  ["logoutMenuLabel", "Cerrar Sesión"],
  [
    "lostSelections",
    "* Sus selecciones no se guardarán a menos que haga clic en el botón Aprobar. Los cambios no guardados se perderán.",
  ],
  ["noOptionsSelector", "Sin opciones disponibles"],
  [
    "noPastOfferings",
    "No se encontraron ofertas anteriores para el distribuidor seleccionado.",
  ],
  [
    "noPermissionForSalesCode",
    "No tiene permiso para someter órdenes para este número de distribuidor.",
  ],
  ["noSVRDataFound", "Sin datos disponibles"],
  ["offerEndsMessage", 'Finaliza la oferta "el", '],
  ["offerNotYetStarted", "La oferta no ha iniciado"],
  ["offerStarts", "Inicio de la oferta:"],
  ["offeringPlaceholderText", "Periodo de Oferta"],
  ["offeringStatusLabel", "Estatus de la Oferta"],
  ["onLabel", " en "],
  ["optionalFeaturesLabel", "Opcionales Incluidos"],
  ["optionalPackagesLabel", "Paquetes Incluidos"],
  ["optionsLabel", "Opciones"],
  [
    "orderGridDefaultMessage",
    "Por favor seleccione el número de distribuidor. Si no aparece en la lista es porque no tiene permiso para someter órdenes.",
  ],
  ["orderHistoryDownloadButton", "Descargar"],
  ["orderHistoryDownloadReportLabel", "Exportar Reporte del Periodo de Oferta"],
  ["orderHistoryFor", " para "],
  ["orderHistoryOf", "Historial de órdenes de "],
  [
    "orderHistoryOfferingDownloadLabel",
    "Descargar Reporte del Periodo de Oferta",
  ],
  ["orderHistoryOfferingPeriodLabel", "Periodo de Oferta"],
  ["orderHistoryRegionLabel", "Región"],
  ["orderHistorySalesCodeLabel", "Código de Distribuidor"],
  ["orderHistoryViewReportLabel", "Reporte Preliminar del Período de Oferta"],
  ["packagesLabel", "Paquetes"],
  ["paintLabel", "Pintura"],
  ["permissionCDSPlaceholder", "CDSID"],
  ["permissionLookup", "Consulta de Permisos"],
  ["reallocateLabel", "Reasignar"],
  ["refreshPage", "Por favor, actualice la página para ver los cambios."],
  ["regionPlaceholderText", "Región"],
  ["rejectLabel", "Rechazar"],
  ["rejectedLabel", "Rechazado"],
  ["salesCodePlaceholderText", "Código de Distribuidor"],
  ["searchButton", "Buscar"],
  ["selectOfferingPeriodPlaceholderText", "Selecciona el Periodo de Oferta"],
  ["selectSalesCodePlaceholderText", "Selecciona el Código de Distribuidor"],
  [
    "selectSalesCodeWarningMessage",
    "Por favor selecciona el Código de Distribuidor",
  ],
  ["selectVehicleLinePlaceholderText", "Selecciona la Línea de Vehículo"],
  [
    "selectVehicleLineWarningMessage",
    "Por favor selecciona la Línea de Vehículo",
  ],
  ["statusLabel", "Estatus"],
  ["submitButtonLabel", "Enviar solicitud"],
  [
    "submitButtonTooltip",
    "Se debe realizar la selección antes de someter los vehículos",
  ],
  ["submitReallocationLabel", "Someter Reasignación"],
  ["supplementalVehicleRequestLabel", "Solicitud de Vehículo Suplementario"],
  [
    "supplementalVehicleRequestMenuLabel",
    "Solicitud de Vehículo Suplementario",
  ],
  [
    "svrWarningMessage",
    "Por favor tome en cuenta que enviar una solicitud no garantiza la disponibilidad de los vehículos para entrega. Recibirá una notificación por correo electrónico sobre el estado de su solicitud.",
  ],
  ["trimLabel", "Interior"],
  ["urgentMessageSuccess", "Su mensaje urgente ha sido enviado correctamente."],
  ["userLabel", "Usuario"],
  ["userProfileLabel", "Perfil de Usuario"],
  ["vehicleLineLabel", "Línea de Vehículo"],
  ["vehicleOfferingLabel", "Oferta de Vehículos"],
  ["vehiclesLeftToReviewText", "Vehículos pendientes de revisión"],
  [
    "vehiclesMustBeRejected",
    "Los vehículos deben rechazarse antes de poder ser reasignados",
  ],
  ["vinLabel", "VIN"],
  ["warningMessageButton", "Subir Mensaje"],
  ["warningMessageHeader", "MENSAJE URGENTE"],
  ["warningMessageMenuLabel", "Establecer mensaje de advertencia"],
  [
    "warningMessagePlaceholder",
    "Por favor, introduzca el contenido del mensaje urgente tal como debe aparecer a los usuarios del portal.",
  ],
]);
const baseContentMap = new Map<string, Map<string, any>>([
  [Language.EN, baseContentEnglish],
  [Language.FR, baseContentFrench],
  [Language.ES, baseContentSpanish],
]);

const baseContentEnglishFormatted = new Map<string, any>([
  [
    "approveButtonMessage",
    "* Your selections will not be saved unless you click the <b>Approve</b> button. Unsaved changes will be lost.",
  ],
  [
    "contactUsHeader1",
    'Having an issue with the portal? For permissions or access issues, please refer to the <a className={"link-styling"} href="/faq">Dealer FAQ</a> page or contact your Vehicle Sales Operations Lead.',
  ],
  [
    "downloadCSVTooltip",
    "Download the report for Supplemental<br/>Vehicle Requests for the current offering.",
  ],
  [
    "lostSelections",
    "* Your selections will not be saved unless you click the <b>Approve</b> button. Unsaved changes will be lost.",
  ],
  [
    "supplementalVehicleRequestMenuLabel",
    "Supplemental <br /> Vehicle Request",
  ],
  [
    "warningMessageText",
    "If you have an <strong>urgent message</strong> that needs to be displayed to <strong>all</strong> Portal users the next time they login, enter it below.",
  ],
]);
const baseContentFrenchFormatted = new Map<string, any>([
  [
    "approveButtonMessage",
    "* Vos sélections ne seront pas enregistrées à moins que vous ne cliquiez sur le bouton <strong> Approuver </strong>. Les changements non enregistrés seront perdus.",
  ],
  [
    "contactUsHeader1",
    'Vous avez un problème avec le portail? Pour les problèmes de permissions ou d\'accès, veuillez consulter <a className={"link-styling"} href="/faq">la FAQ_pour les concessionnaires</a> ou votre Responsable des opérations, ventes de véhicules.',
  ],
  [
    "downloadCSVTooltip",
    "Téléchargez le rapport pour demander <br/>plus de véhicules que l’offre actuelle.",
  ],
  [
    "lostSelections",
    "* Vos sélections ne seront pas enregistrées à moins que vous ne cliquiez sur le bouton <b>Approuver</b>. Les changements non enregistrés seront perdus.",
  ],
  [
    "supplementalVehicleRequestMenuLabel",
    "Demandes de <br/> véhicules supplémentaires",
  ],
  [
    "warningMessageText",
    "Si vous avez un <strong>message urgent</strong> à afficher pour <strong>tous</strong> les utilisateurs du portail à leur prochaine connexion, entrez-le ci-dessous",
  ],
]);
const baseContentSpanishFormatted = new Map<string, any>([
  [
    "warningMessageText",
    "Si tiene un <strong>mensaje urgente</strong> que deba mostrarse a <strong>todos</strong> los usuarios del portal la próxima vez que inicien sesión, introdúzcalo a continuación.",
  ],
]);
const baseContentFormattedMap = new Map<string, Map<string, any>>([
  [Language.EN, baseContentEnglishFormatted],
  [Language.FR, baseContentFrenchFormatted],
  [Language.ES, baseContentSpanishFormatted],
]);

const cvwContentEnglish = new Map<string, any>([
  ["orderHistoryPageSubject", "ORDER HISTORY"],
  [
    "orderHistoryPageDescription",
    "Download reports of accepted, rejected, and allocated vehicles in each offer period.",
  ],
  [
    "tooltip",
    "Note: Once rebilling of accepted Configured Vehicle Wholesale units has been completed, the orders will become visible in WBDO.",
  ],
  ["title", "Configured Vehicle Wholesale"],
  ["portalTitleText", "CONFIGURED VEHICLE WHOLESALE OFFERS"],
  [
    "submitModalNote",
    "Note: For Configured Vehicle Wholesale units, no changes to the order will be able to be made.",
  ],
  [
    "disclaimerAcceptanceLabel",
    "I have read and understand how allocation has been distributed.",
  ],
  ["headerLabel", "CONFIGURED VEHICLE WHOLESALE"],
  [
    "allocationMethodology",
    "The Rapid Replenishment SVA and 3-month Share of Region formula was utilized to distribute both Bronco Sport and F150 Hybrid CVW allocation.",
  ],
  [
    "submitButtonTooltipOfferClosed",
    "Configured Vehicle Wholesale offer window is closed",
  ],
  [
    "permissionsErrorMessage",
    "You do not have permission to submit wholesale orders.",
  ],
  [
    "contactDealerAdmin",
    "If you think this is an error, please contact your Dealer Administrator.",
  ],
  [
    "orderGridHelpMessage",
    "If you have further questions, please reach out to your regional Sales and Inventory Specialist.",
  ],
  [
    "financialResponsibilityLabel",
    "By submitting you are accepting financial responsibility for the vehicles you accepted.",
  ],
  ["allocationMethodologyNoticeTitle", "Allocation Methodology Notice"],
  [
    "modalDescriptionPartOne",
    "Bronco (Configured Vehicle Wholesale) and Regional Configured Wholesale",
  ],
  [
    "modalDescriptionPartTwo",
    "(all other vehicle lines) allocated based on Rolling 3 Month Sales Share of Region and SVA",
  ],
  ["orderHistoryMenuButton", "Order History"],
  [
    "faqFordAcceptPortal",
    "Who can accept portal based allocation at the dealership? How can I gain access?",
  ],
  [
    "faqFordFIIAuthority",
    "Persons with FII authority at the dealership should be accepting Configured Vehicle wholesale allocation. Persons must have “Order Management Rapid_Replenishment” permission to complete the wholesale in the portal. Please contact your administrator for assistance.",
  ],
  [
    "faqFordNoVehicleOfferings",
    "No vehicle offerings are showing when I enter my sales code. What does this mean?",
  ],
  [
    "faqFordDealershipEarned",
    "Dealership earned zero (0) units for the offering period for the displayed vehicle line. Be sure to check all vehicle lines using the vehicle line drop down.",
  ],
  [
    "faqFordAcceptedBeRebilled",
    "When will my accepted units be rebilled to my dealership?",
  ],
  [
    "faqFordAcceptedBeRebilledA",
    "Accepted units will be rebilled to dealership within ten (10) business days of portal closure. Please check the FMC Dealer Message Center posting for exact dates.",
  ],
  ["faqFordAmendOrders", "Can I amend any items on my accepted orders?"],
  [
    "faqFordAmendOrdersA",
    "Once units have been accepted and rebilled, the orders will become visible in WBDO. At that point, dealers will not be able to adjust features.",
  ],
  ["faqFordITTroubles", "I am having IT troubles with the site."],
  [
    "faqFordITTroublesA",
    "Please try another browser or clear your cache. Also, only persons with authority to approve Rapid Replenishment wholesale (FII) can access the offering. If further assistance is needed, please utilize the “Contact Us” feature on the portal site via the menu drop down.",
  ],
  [
    "faqFordFindAccepted",
    "How can I find my accepted Configured Vehicle Wholesale units in WBDO?",
  ],
  [
    "faqFordFindAcceptedA",
    "Filter to Vehicle line, filter to priority code 80",
  ],
]);
const cvwContentSpanish = new Map<string, any>([
  ["orderHistoryPageSubject", "Historial de Órdenes"],
  [
    "orderHistoryPageDescription",
    "Descargue informes de vehículos aceptados, rechazados y asignados en cada período de oferta.",
  ],
  [
    "tooltip",
    " Nota: Una vez completada la refacturación de los vehículos configurados (CVW) aceptados, las órdenes serán visibles en WBDO.",
  ],
  ["title", "Órdenes Vehículos Preconfigurados"],
  ["portalTitleText", "Órdenes Ofertadas de Vehículos Preconfigurados"],
  [
    "submitModalNote",
    "Nota: No se podrán realizar cambios en las órdenes de vehículos preconfigurados.",
  ],
  [
    "disclaimerAcceptanceLabel",
    "He leído y entiendo cómo se distribuyen las asignaciones disponibles",
  ],
  ["headerLabel", " Órdenes Vehículos Preconfigurados/CVW"],
  [
    "allocationMethodology",
    "Se utilizó la fórmula de Rapid Replenishment SVA y la fórmula de participación regional de 3 meses para distribuir las asignaciones de CVW para Bronco Sport y F150-HEV.",
  ],
  [
    "submitButtonTooltipOfferClosed",
    "La oferta de Vehículos Preconfigurados está cerrada",
  ],
  ["permissionsErrorMessage", "No tiene permiso para someter órdenes."],
  [
    "contactDealerAdmin",
    "Si cree que se trata de un error, por favor, póngase en contacto con el administrador del concesionario.",
  ],
  [
    "orderGridHelpMessage",
    "Si tiene más preguntas, póngase en contacto con su gerente de zona.",
  ],
  [
    "financialResponsibilityLabel",
    "Al someter/aprobar, usted asume la responsabilidad financiera de los vehículos que ha aceptado.",
  ],
  [
    "allocationMethodologyNoticeTitle",
    "Notificación sobre Método de Asignación",
  ],
  [
    "modalDescriptionPartOne",
    "Bronco (Órdenes de Vehículos Preconfigurados) y mayoreo configurado por región",
  ],
  [
    "modalDescriptionPartTwo",
    "(todas las otras lines de vehículo) asignado según la participación de ventas regional móvil de 3 meses y el SVA",
  ],
  ["orderHistoryMenuButton", "Historial de Órdenes"],
]);
const cvwContentMap = new Map<string, Map<string, any>>([
  [Language.EN, cvwContentEnglish],
  [Language.ES, cvwContentSpanish],
]);

const cvwContentEnglishFormatted = new Map<string, any>([
  [
    "securityAssistance",
    'If you need assistance identifying your Security Provisioning Services (SPS) Administrator, please contact the Business Assistance Center (BAC) at <strong>800-790-4357</strong> or visit the <a style="text-decoration: underline; color: blue" href="https://www.fmcdealer.dealerconnection.com/content/fmcdealer/en/us/Help/wsgchatlaunch.html"> FMCDealer Help Chat</a>.',
  ],
]);
const cvwContentSpanishFormatted = new Map<string, any>([
  [
    "securityAssistance",
    "Si necesita ayuda para identificar a su administrador de Servicios de Provisionamiento de Seguridad (SPS), comuníquese con el Centro de Asistencia a Empresas (BAC) al <strong>800-790-4357</strong> o use el Chat de Ayuda FMCDealer.",
  ],
]);
const cvwContentMapFormatted = new Map<string, Map<string, any>>([
  [Language.EN, cvwContentEnglishFormatted],
  [Language.ES, cvwContentSpanishFormatted],
]);

const ipwContentEnglish = new Map<string, any>([
  ["orderHistoryPageSubject", "ORDER HISTORY"],
  [
    "orderHistoryPageDescription",
    "Download reports of accepted, rejected, and allocated vehicles in each offer period.",
  ],
  [
    "tooltip",
    "Note: Once rebilling of accepted Incremental Production Wholesale units has been completed, the orders will become visible in WBDO.",
  ],
  ["title", "Incremental Production Wholesale"],
  ["portalTitleText", "INCREMENTAL PRODUCTION WHOLESALE OFFERS"],
  [
    "submitModalNote",
    "Note: For Incremental Production Wholesale units, no changes to the order will be able to be made.",
  ],
  [
    "disclaimerAcceptanceLabel",
    "I have read and understand how allocation has been distributed.",
  ],
  ["headerLabel", "INCREMENTAL PRODUCTION WHOLESALE"],
  [
    "allocationMethodology",
    "The Rapid Replenishment SVA and 3-month Share of Region formula was utilized to distribute both Bronco Sport and F150 Hybrid IPW allocation.",
  ],
  [
    "submitButtonTooltipOfferClosed",
    "Incremental Production Wholesale offer window is closed",
  ],
  [
    "permissionsErrorMessage",
    "You do not have permission to submit wholesale orders.",
  ],
  [
    "contactDealerAdmin",
    "If you think this is an error, please contact your Dealer Administrator.",
  ],
  [
    "orderGridHelpMessage",
    "If you have further questions, please reach out to your regional Sales and Inventory Specialist.",
  ],
  [
    "financialResponsibilityLabel",
    "By submitting you are accepting financial responsibility for the vehicles you accepted.",
  ],
  ["allocationMethodologyNoticeTitle", "Allocation Methodology Notice"],
  [
    "modalDescriptionPartOne",
    "Bronco (Incremental Production Wholesale) and Regional Configured Wholesale",
  ],
  [
    "modalDescriptionPartTwo",
    "(all other vehicle lines) allocated based on Rolling 3 Month Sales Share of Region and SVA",
  ],
  ["orderHistoryMenuButton", "Order History"],
  [
    "faqFordAcceptPortal",
    "Who can accept portal based allocation at the dealership? How can I gain access?",
  ],
  [
    "faqFordFIIAuthority",
    "Persons with FII authority at the dealership should be accepting Incremental Production Wholesale (IPW) allocation. Persons must have “Order Management Rapid_Replenishment” permission to complete the wholesale in the portal. Please contact your administrator for assistance.",
  ],
  [
    "faqFordNoVehicleOfferings",
    "No vehicle offerings are showing when I enter my sales code. What does this mean?",
  ],
  [
    "faqFordDealershipEarned",
    "Dealership earned zero (0) units for the offering period for the displayed vehicle line. Be sure to check all vehicle lines using the vehicle line drop down.",
  ],
  [
    "faqFordAcceptedBeRebilled",
    "When will my accepted units be rebilled to my dealership?",
  ],
  [
    "faqFordAcceptedBeRebilledA",
    "Accepted units will be rebilled to dealership within ten (10) business days of portal closure. Please check the FMC Dealer Message Center posting for exact dates.",
  ],
  ["faqFordAmendOrders", "Can I amend any items on my accepted orders?"],
  [
    "faqFordAmendOrdersA",
    "Once units have been accepted and rebilled, the orders will become visible in WBDO. At that point, dealers will not be able to adjust features.",
  ],
  ["faqFordITTroubles", "I am having IT troubles with the site."],
  [
    "faqFordITTroublesA",
    "Please try another browser or clear your cache. Also, only persons with authority to approve Incremental Production Wholesale (IPW) FII can access the offering. If further assistance is needed, please utilize the “Contact Us” feature on the portal site via the menu drop down.",
  ],
  [
    "faqFordFindAccepted",
    "How can I find my accepted Incremental Production Wholesale (IPW) units in WBDO?",
  ],
  [
    "faqFordFindAcceptedA",
    "Filter to Vehicle line, filter to priority code 80",
  ],
]);
const ipwContentMap = new Map<string, Map<string, any>>([
  [Language.EN, ipwContentEnglish],
]);

const ipwContentEnglishFormatted = new Map<string, any>([
  [
    "securityAssistance",
    'If you need assistance identifying your Security Provisioning Services (SPS) Administrator, please contact the Business Assistance Center (BAC) at <strong>800-790-4357</strong> or visit the <a style="text-decoration: underline; color: blue" href="https://www.fmcdealer.dealerconnection.com/content/fmcdealer/en/us/Help/wsgchatlaunch.html"> FMCDealer Help Chat</a>.',
  ],
]);
const ipwContentMapFormatted = new Map<string, Map<string, any>>([
  [Language.EN, ipwContentEnglishFormatted],
]);

const mewContentEnglish = new Map<string, any>([
  ["orderHistoryPageDescription", " "],
  ["title", "Model E Wholesale"],
  ["portalTitleText", "MODEL E STOCK REPLENISHMENT"],
  [
    "submitModalNote",
    "Note: The configurations submitted are not guaranteed, but will be on hold pending approval.",
  ],
  ["totalAllowedTitle", "Total Allowed"],
  ["totalAllowedToolTip", "Total number of vehicles allowed on-hand"],
  ["stockTargetTitle", "Stock Guide"],
  ["stockTargetToolTip", "Ideal number of vehicles on lot"],
  ["demoAllowanceTitle", "Demo Allowance"],
  ["demoAllowanceToolTip", "Ford Pro CVC Demo Program Use Only"],
  ["totalAvailableTitle", "Total Available"],
  ["totalAvailableToolTip", "In Production + In Transit + In Stock Units"],
  ["stockBtgTitle", "Stock Replenishment BTG"],
  [
    "stockBtgToolTip",
    "The number of vehicles you can request for stock (Total Allowed - Ground Stock)",
  ],
  ["MSRPTitle", "MSRP"],
  ["MSRPToolTip", "Total MSRP = Base MSRP + Options + D&D"],
  ["headerLabel", "MODEL E DEALER STOCK REPLENISHMENT PORTAL"],
  [
    "ageThresholdTitle",
    "MODEL E STOCK REPLENISHMENT AGE THRESHOLD CONFIGURATION",
  ],
  [
    "ageSubHeaderText",
    "Filter by RRC, vehicle line, model year, body, or trim",
  ],
  ["orderHistoryPageSubject", "STOCKING REQUEST HISTORY"],
  [
    "permissionsErrorMessage",
    "You do not have access to the EV Dealer Stocking Portal.",
  ],
  [
    "financialResponsibilityLabel",
    "By approving, you are formally requesting that Ford ship the specified vehicle configurations to you.",
  ],
  [
    "Question1Label",
    "Which permissions do a dealership employee need to access the EV Dealer Stocking Portal?",
  ],
  ["Question2Label", "Can I amend any items on my accepted orders?"],
  [
    "Answer2Label",
    "Model e Vehicles at the RRCs have already been produced and cannot be amended. Additionally, once “Approve” is clicked during the order process, selected configurations, or quantities for the specific order, may no longer be amended.",
  ],
  ["Question3Label", "I am having IT troubles with the site."],
  [
    "Answer3Label",
    "It is often hard to tell if there is a connection issue or a true software issue.  Please be sure to check the simple things like Wi-Fi connectivity, clearing your cache or simply switching browsers.\n" +
      "If you still believe there is an issue, please utilize the Contact Us form under the Menu button (top, right hand side of the screen).",
  ],
  ["Question4Label", "How can I find the status of my prior requests?"],
  [
    "Answer4Label",
    "The Stocking Request History Screen shows the status of recent requests.",
  ],
  [
    "Question5Label",
    "Can I still order vehicles in WBDO? (2024 F-150 Lightning / 2024 Mustang Mach e)?",
  ],
  [
    "Question6Label",
    "What if I have questions about the stock guide for my dealership?",
  ],
  [
    "Answer6Label",
    "Contact your Model e Development Specialist. The specialist can walk you through the Dealer Stocking Guide methodology & your dealership’s stocking guide calculation.",
  ],
  ["orderHistoryMenuButton", "Stocking Request History"],
]);
const mewContentMap = new Map<string, Map<string, any>>([
  [Language.EN, mewContentEnglish],
]);

const mewContentEnglishFormatted = new Map<string, any>([
  [
    "contactDealerAdmin",
    "If you think this is an error, </br></br>" +
      '•\tPlease see your Dealer Administrator (typically one designated at each Dealership) to look up and provide entitlements for a user at: <a style="text-decoration: underline; color: blue" href="https://www.dsps.dealerconnection.com/Sps/index.jsp">https://www.dsps.dealerconnection.com/Sps/index.jsp</a>' +
      "</br></br>" +
      '•\tThe Dealer Administrator can find full information at: <a style="text-decoration: underline; color: blue" href="https://www.ordermanagement2.dealerconnection.com/soms/pdf/Security Setup - US Dealers_Job Aid_Aug 2019.pdf">Security Setup - US Dealers_Job Aid_Aug 2019.pdf (dealerconnection.com)</a>' +
      "</br></br>" +
      "•\tTraining may be provided by your Model e Development Specialist",
  ],
  [
    "securityAssistance",
    "If Further Questions:" +
      'Please reach out to your Model e Development Specialist or <a style="text-decoration: underline; color: blue" href="mailto:ModelePHQ@fordprogramhq.com">ModelePHQ@fordprogramhq.com</a> ' +
      "(888-850-1002) Monday through Friday, 9:30 AM-7:00 PM ET",
  ],
  [
    "Answer1Label",
    'Please see your Dealer Administrator (typically one designated at each Dealership) to look up and provide entitlements for a user at: <a style="text-decoration: underline; color: blue" href="https://www.dsps.dealerconnection.com/Sps/index.jsp">https://www.dsps.dealerconnection.com/Sps/index.jsp</a></br></br>•\tThe Dealer Administrator can find full information at: <a style="text-decoration: underline; color: blue" href="https://www.ordermanagement2.dealerconnection.com/soms/pdf/Security Setup - US Dealers_Job Aid_Aug 2019.pdf">Security Setup - US Dealers_Job Aid_Aug 2019.pdf (dealerconnection.com)</a>\n</br>' +
      "•\tTraining may be provided by your Model e Development Specialist",
  ],
  [
    "Answer5Label",
    "<strong><u>24MY Mustang Mach:</u></strong>  Participates in monthly wholesale process and utilizes WBDO for vehicle ordering. Dealerships are responsible for submitting vehicle orders.  </br><strong><u>24MY F-150 Lightning:</u></strong>  Participates in Retail Replenishment Center(RRC) Pilot. Orders are generated by Ford and allocated to each RRC.  Dealers can replenish stock daily via the EV Dealer Stock Portal, fulfilled directly from RRC. ",
  ],
]);
const mewContentMapFormatted = new Map<string, Map<string, any>>([
  [Language.EN, mewContentEnglishFormatted],
]);

const lpivwContentEnglish = new Map<string, any>([
  ["orderHistoryPageSubject", "ORDER HISTORY"],
  [
    "orderHistoryPageDescription",
    "Download reports of accepted, rejected, and allocated vehicles in each offer period.",
  ],
  [
    "tooltip",
    "Produced Import Vehicle Wholesale offerings are produced vehicles and therefore options and features cannot be amended.",
  ],
  ["title", "Lincoln Produced Import Vehicle Wholesale"],
  ["portalTitleText", "PRODUCED IMPORT VEHICLE WHOLESALE OFFERS"],
  [
    "submitModalNote",
    "Note: For Produced Import Vehicle Wholesale units, no changes to the order will be able to be made.",
  ],
  [
    "disclaimerAcceptanceLabel",
    "I have read and understand how allocation has been distributed.",
  ],
  ["headerLabel", "PRODUCED IMPORT VEHICLE WHOLESALE"],
  [
    "allocationMethodology",
    "Lincoln Retailers will earn Produced Import Vehicle Wholesale offerings based on most recent 3 month, month end sales history and SVA.",
  ],
  [
    "submitButtonTooltipOfferClosed",
    "Produced Import Vehicle Wholesale offer window is closed",
  ],
  [
    "permissionsErrorMessage",
    "You do not have permission to submit wholesale orders.",
  ],
  [
    "contactDealerAdmin",
    "If you think this is an error, please contact your Dealer Administrator.",
  ],
  [
    "orderGridHelpMessage",
    "If you have further questions, please reach out to your Vehicle Sales Operations Lead.",
  ],
  [
    "financialResponsibilityLabel",
    "By submitting you are accepting financial responsibility for the vehicles you accepted.",
  ],
  [
    "Question1Label",
    "Who can accept portal based allocation at the retailer? How can I gain access?",
  ],
  [
    "Answer1Label",
    "Persons with FII authority at the retailer should be accepting Produced Import Vehicle wholesale allocation. Persons must have “Order Management: Rapid_Replenishment” permission to complete the wholesale in the portal. Please contact your administrator for assistance.",
  ],
  [
    "Question2Label",
    "No vehicle offerings are showing when I enter my sales code. What does this mean?",
  ],
  [
    "Answer2Label",
    "Retailer earned zero (0) units for the offering period for the displayed vehicle line. Be sure to check all vehicle lines using the vehicle line drop down.",
  ],
  ["Question3Label", "When will my accepted units be rebilled to my retailer?"],
  [
    "Answer3Label",
    "Accepted units will be rebilled to retailer within ten (10) business days of portal closure. Please check the FMC Dealer Message Centerposting for exact dates.",
  ],
  ["Question4Label", "Can I amend any items on my accepted orders?"],
  [
    "Answer4Label",
    "Vehicles that are offered in Produced Import Vehicle Wholesale are produced. No amendments may be made on any accepted units.",
  ],
  ["Question5Label", "I am having IT troubles with the site."],
  [
    "Answer5Label",
    "Please try another browser or clear your cache. Also, only persons with authority to approve Produced Import Vehicle Wholesale (FII) can access the offering. If further assistance is needed, please utilize the “Contact Us” feature on the portal site via the menu drop down.",
  ],
  [
    "Question6Label",
    "How can I find my accepted Produced Import Vehicle units in WBDO?",
  ],
  [
    "Answer6Label",
    "Produced Import Vehicle Wholesaled units are released from the plant and therefore will not display in WBDO.",
  ],
  ["orderHistoryMenuButton", "Order History"],
]);
const lpivwContentFrench = new Map<string, any>([
  [
    "tooltip",
    "Remarque : Une fois que la refacturation des unités de vente en gros de véhicules importés produits est terminée, les commandes deviennent visibles dans l’outil de commandes des concessionnaires sur Internet (WBDO). Aucune modification ne pourra être apportée à la commande. ",
  ],
  ["portalTitleText", "VENTE EN GROS DE VÉHICULES IMPORTÉS PRODUITS"],
  ["title", "Lincoln Produced Import Vehicle Wholesale"],
  [
    "submitModalNote",
    "Remarque: Pour les unités de vente en gros de véhicules importés produits, aucune modification ne pourra être apportée à la commande.",
  ],
  ["headerLabel", "VENTE EN GROS DE VÉHICULES IMPORTÉS PRODUITS"],
  [
    "allocationMethodology",
    "Les détaillants du Comité canadien des produits Nautilus se verront offrir une unité test pour la vente en gros de véhicules importés produits.",
  ],
  [
    "submitButtonTooltipOfferClosed",
    "La période de l’offre sur les unités de vente en gros de véhicules importés produits est terminée.",
  ],
  [
    "permissionsErrorMessage",
    "Vous n’avez pas la permission de soumettre des commandes en gros.",
  ],
  [
    "orderGridHelpMessage",
    "Si vous avez d’autres questions, veuillez communiquer avec votre Responsable des Opérations, Ventes de Véhicules.",
  ],
  [
    "disclaimerAcceptanceLabel",
    "J'ai lu et compris comment l'allocation était distribuée.",
  ],
  [
    "financialResponsibilityLabel",
    "En soumettant votre demande, vous acceptez la responsabilité financière des véhicules que vous avez acceptés.",
  ],
  [
    "Question1Label",
    "Qui peut accepter l’allocation dans le portail chez le détaillant? Comment puis-je y avoir accès?",
  ],
  [
    "Answer1Label",
    "Les signataires autorisés des détaillants doivent accepter l’allocation de véhicules importés produits pour la vente en gros. Les personnes doivent avoir la permission « Order Management: Rapid_Replenishment » pour conclure la vente en gros dans le portail. Veuillez communiquer avec votre administrateur pour obtenir de l’aide.",
  ],
  [
    "Question2Label",
    "Aucune offre de véhicule ne s’affiche quand j’entre mon code de vente. Qu’est-ce que cela signifie?",
  ],
  [
    "Answer2Label",
    "Le détaillant n’a accumulé aucun (0) véhicule de la gamme affichée pendant la période de l’offre. Assurez-vous de vérifier toutes les gammes de véhicules à l’aide du menu déroulant.",
  ],
  [
    "Question3Label",
    "Quand refacturera-t-on mes véhicules acceptés à mon détaillant?",
  ],
  [
    "Answer3Label",
    "Les véhicules acceptés seront refacturés au détaillant dans les dix (10) jours ouvrables suivant la fermeture du portail. Veuillez consulter le Centre de messages dans FMCDealer pour connaître les dates exactes.",
  ],
  [
    "Question4Label",
    "Puis-je modifier des articles sur mes commandes acceptées?",
  ],
  [
    "Answer4Label",
    "Les véhicules importés produits offerts pour la vente en gros sont tous produits. Aucune modification ne peut être apportée aux véhicules acceptés.",
  ],
  [
    "Question5Label",
    "J’ai des problèmes techniques m’empêchant d’accéder au site.",
  ],
  [
    "Answer5Label",
    "Veuillez essayer un autre navigateur ou vider la mémoire cache. De plus, seules les personnes pouvant approuver les véhicules importés produits en gros (signataires autorisés) peuvent accéder à l’offre. Si vous avez besoin d’assistance supplémentaire, veuillez utiliser la fonction « Communiquez avec nous » dans le menu déroulant du portail.",
  ],
  [
    "Question6Label",
    "Comment puis-je trouver mes véhicules importés produits dans WBDO?",
  ],
  [
    "Answer6Label",
    "Les véhicules importés produits vendus en gros quitteront l’usine et ne seront donc pas affichés dans WBDO.",
  ],
  ["orderHistoryMenuButton", "Historique des commandes"],
]);
const lpivwContentMap = new Map<string, Map<string, any>>([
  [Language.EN, lpivwContentEnglish],
  [Language.FR, lpivwContentFrench],
]);

const lpivwContentEnglishFormatted = new Map<string, any>([
  [
    "securityAssistance",
    'If you need assistance identifying your Security Provisioning Services (SPS) Administrator, please contact the Business Assistance Center (BAC) at <strong>800-790-4357</strong> or visit the <a style="text-decoration: underline; color: blue" href="https://www.fmcdealer.dealerconnection.com/content/fmcdealer/en/us/Help/wsgchatlaunch.html"> FMCDealer Help Chat</a>.',
  ],
]);
const lpivwContentFrenchFormatted = new Map<string, any>([
  [
    "securityAssistance",
    'Si vous avez besoin d’assistance pour déterminer qui est votre administrateur des Services de sécurité, veuillez communiquer avec le Centre d’Assistance des Affaires au <strong>800-790-4357</strong> ou accéder à la fonctionnalité de clavardage dans <a style="text-decoration: underline; color: blue" href="https://www.fmcdealer.dealerconnection.com/content/fmcdealer/en/us/Help/wsgchatlaunch.html"> FMCDealer pour obtenir de l’aide. </a>',
  ],
]);
const lpivwContentMapFormatted = new Map<string, Map<string, any>>([
  [Language.EN, lpivwContentEnglishFormatted],
  [Language.FR, lpivwContentFrenchFormatted],
]);

const mapContentEnglish = new Map<string, any>([
  ["orderHistoryPageSubject", "ORDER HISTORY"],
  [
    "orderHistoryPageDescription",
    "Download reports of accepted, rejected, and allocated vehicles in each offer period.",
  ],
  [
    "tooltip",
    "Note: Once rebilling of accepted Market Aligned Production units has been completed, the orders will become visible in WBDO. At that point, dealers may be able to adjust some features such as paint and dealer installed options before the order amendment window closes.",
  ],
  ["title", "Market Aligned Production"],
  ["portalTitleText", "MARKET ALIGNED PRODUCTION OFFERS"],
  [
    "submitModalNote",
    "Note: Once rebilling of accepted Market Aligned Production units has been completed, the orders will become visible in WBDO. At that point, dealers may be able to adjust some features such as paint and dealer installed options before the order amendment window closes.",
  ],
  ["headerLabel", "MARKET ALIGNED PRODUCTION"],
  [
    "submitButtonTooltipOfferClosed",
    "Market Aligned Production offer window is closed",
  ],
  [
    "contactDealerAdmin",
    "If you feel you have the correct permissions, please try a new browser or clear your cookies. If this doesn't work, please contact your Dealer Administrator.",
  ],
  ["permissionsErrorMessage", "You are not able to view this page."],
  [
    "orderGridHelpMessage",
    "If you have further questions, please reach out to your regional Sales and Inventory Specialist.",
  ],
  [
    "financialResponsibilityLabel",
    "By submitting you are accepting financial responsibility for the vehicles you accepted.",
  ],
  ["orderHistoryMenuButton", "Order History"],
  [
    "faqFordAcceptPortal",
    "Who can accept portal based allocation at the dealership? How can I gain access?",
  ],
  [
    "faqFordFIIAuthority",
    "Persons with FII authority at the dealership should be accepting Market Aligned Production (MAP) wholesale allocation. Persons must have “Order Management Rapid_Replenishment” permission to complete the wholesale in the portal. Please contact your administrator for assistance.",
  ],
  [
    "faqFordNoVehicleOfferings",
    "No vehicle offerings are showing when I enter my sales code. What does this mean?",
  ],
  [
    "faqFordDealershipEarned",
    "Dealership earned zero (0) units for the offering period for the displayed vehicle line. Be sure to check all vehicle lines using the vehicle line drop down.",
  ],
  [
    "faqFordAcceptedBeRebilled",
    "When will my accepted units be rebilled to my dealership?",
  ],
  [
    "faqFordAcceptedBeRebilledA",
    "Accepted units will be rebilled to dealership within ten (10) business days of portal closure. Please check the FMC Dealer Message Center posting for exact dates.",
  ],
  ["faqFordAmendOrders", "Can I amend any items on my accepted orders?"],
  [
    "faqFordAmendOrdersA1",
    "Once units have been accepted and rebilled, the orders will become visible in WBDO. At that point, dealers may be able to adjust some features such as paint and dealer installed options before the order amendment window closes.",
  ],
  ["faqFordITTroubles", "I am having IT troubles with the site."],
  [
    "faqFordITTroublesA",
    "Please try another browser or clear your cache. Also, only persons with authority to approve Market Aligned Production (MAP) wholesale (FII) can access the offering. If further assistance is needed, please utilize the “Contact Us” feature on the portal site via the menu drop down.",
  ],
  [
    "faqFordFindAccepted",
    "How can I find my accepted Rapid Replenishment units in WBDO?",
  ],
  [
    "faqFordFindAcceptedA1",
    "Utilize the “Display” Drop Down on the Orders tab to select Market Aligned Production (MAP)",
  ],
]);
const mapContentFrench = new Map<string, any>([
  [
    "contactDealerAdmin",
    "If you think this is an error, please contact your Dealer Administrator.",
  ],
  [
    "permissionsErrorMessage",
    "Vous n’avez pas la permission de soumettre des commandes en gros.",
  ],
  ["portalTitleText", "OFFRES DE RÉAPPROVISIONNEMENT RAPIDE"],
  [
    "tooltip",
    "Remarque : une fois que le re-facturation des unités de remplissage rapide acceptées est terminée, les commandes seront visibles dans WBDO. À ce moment-là, les concessionnaires pourront peut-être ajuster certaines fonctionnalités telles que la peinture et les options installées par le concessionnaire avant la fermeture de la fenêtre de modification de la commande.",
  ],
  ["title", "Remplissage rapide"],
  ["headerText", "OFFRES DE REMPLISSAGE RAPIDE"],
  [
    "submitModalNote",
    "Remarque : Une fois que la refacturation des véhicules pour lesquels un réapprovisionnement rapide a été accepté est terminée, les commandes deviennent visibles dans l’outil de commandes des concessionnaires sur Internet (WBDO). Les concessionnaires peuvent à ce moment ajuster certaines caractéristiques comme la peinture et l’équipement en option installé par le concessionnaire avant la fin de la période de modification des commandes.",
  ],
  ["headerLabel", "REMPLISSAGE RAPIDE"],
  [
    "submitButtonTooltipOfferClosed",
    "La période de l’offre sur les unités de vente en remplissage rapide est terminée.",
  ],
  [
    "orderGridHelpMessage",
    "Si vous avez d’autres questions, veuillez communiquer avec votre spécialiste des ventes et des commandes de stockage à l’échelle régionale.",
  ],
  [
    "financialResponsibilityLabel",
    "En soumettant votre demande, vous acceptez la responsabilité financière des véhicules que vous avez acceptés.",
  ],
  ["orderHistoryMenuButton", "Historique des commandes"],
]);
const mapContentMap = new Map<string, Map<string, any>>([
  [Language.EN, mapContentEnglish],
  [Language.FR, mapContentFrench],
]);

const mapContentEnglishFormatted = new Map<string, any>([
  [
    "securityAssistance",
    "If you need assistance identifying your Security Provisioning Services (SPS) Administrator, please contact the Web Support Group at (<strong>(800) 790-4357</strong> option 2, 1) for US and Canadian dealers.",
  ],
]);
const mapContentFrenchFormatted = new Map<string, any>([
  [
    "securityAssistance",
    "Si vous avez besoin d’aide pour trouver votre administrateur des Services de sécurité, veuillez communiquer avec le Centre d’assistance des STF par téléphone au <strong>1 800 429-5527</strong>.",
  ],
]);
const mapContentMapFormatted = new Map<string, Map<string, any>>([
  [Language.EN, mapContentEnglishFormatted],
  [Language.FR, mapContentFrenchFormatted],
]);
